<template>
  <div class="basic-data-form q-pa-md">
    <q-form>
      <div class="form-group">
        <label>Name of asset</label>
        <q-input
          filled
          v-model="assetName"
          :disabled="isAssetCreated"
          id="assetName"
        />
      </div>

      <div class="form-group">
        <label>Projected date of submission regulatory dossier to EMA</label>
        <q-input
          filled
          v-model="dateSubmission"
          type="month"
          id="dateSubmission"
        />
      </div>

      <div class="form-group">
        <label>Responsible for PICO Planning</label>
        <div class="responsible-person-container">
          <div
            v-for="(responsible, index) in responsiblePersons"
            :key="index"
            class="responsible-person q-mb-md"
          >
            <q-select
              v-model="responsible.user"
              :options="
                userNames.map((user) => ({
                  label: `${user.name} ${user.vorname}`,
                  value: user,
                }))
              "
              label="Select User"
              class="select-user"
              @update:modelValue="(value) => handleUserSelection(value, index)"
              :emit-value="true"
              map-options
            />
            <div class="function-label">{{ responsible.userRole }}</div>
            <q-btn
              v-if="responsible.userRole !== 'Main Responsible'"
              @click="setMainResponsible(index)"
              label="Set as Main Responsible"
              color="primary"
              class="set-main-btn"
            />
            <q-btn
              flat
              round
              color="red"
              icon="close"
              size="sm"
              @click="removeResponsiblePerson(index)"
              class="remove-responsible-btn"
            />
          </div>
          <q-btn
            @click="addResponsiblePerson"
            label="Add Responsible Person"
            color="primary"
            class="add-responsible-btn"
          />
        </div>
      </div>

      <label>Orphan drug?</label>
      <DropdownCellView
        :modelValue="orphanDrugContent"
        :options="yesNoOptions"
        :isMandatory="false"
        @update:modelValue="handleOrphanDrugModelValueUpdate"
        class="neumorphic-dropdown"
      />

      <div class="form-actions">
        <q-btn @click="confirmBasicData" label="Confirm" color="primary" />
      </div>
    </q-form>
  </div>
</template>

<script>
import DropdownCellView from '@/core_controls/GipamCells/GipamDropdownCellView.vue'
import axios from 'axios'

export default {
  name: 'BasicDataForm',
  components: {
    DropdownCellView,
  },
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      id: '',
      assetName: '',
      isAssetCreated: false,
      dateSubmission: '',
      responsiblePersons: [],
      userNames: [],
      orphanDrugContent: null,
      yesNoOptions: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' },
      ],
      currentUserId: null,
    }
  },
  mounted() {
    this.populateFormFields()
  },
  methods: {
    async populateFormFields() {
      try {
        const response = await axios.get('jca/getUserNames')
        this.userNames = response.data

        const currentUserResponse = await axios.get('/jca/getUserNameForUserId')
        this.currentUserId = currentUserResponse.data.id

        this.responsiblePersons.push({
          user: {
            id: this.currentUserId,
            name: currentUserResponse.data.name,
            vorname: currentUserResponse.data.vorname,
          },
          userRole: 'Main Responsible',
        })

        const backendDataRes = await axios.get(
          `jca/getBasicDatasForAsset/${this.initialData.id}`,
        )
        const backendData = backendDataRes.data[0]

        if (backendData || this.initialData) {
          this.assetName = backendData?.assetName || this.initialData.assetName
          this.id = backendData.id
          const dateSubmissionObj = new Date(backendData.dateSubmission)
          const year = dateSubmissionObj.getFullYear()
          const month = String(dateSubmissionObj.getMonth() + 1).padStart(
            2,
            '0',
          )
          this.dateSubmission = `${year}-${month}`
          this.orphanDrugContent =
            backendData.orphanDrug === 1
              ? 'Yes'
              : backendData.orphanDrug === 0
                ? 'No'
                : null

          backendData.responsiblePersons.forEach((resp) => {
            const user = this.userNames.find((user) => user.id === resp.userId)
            if (user) {
              this.responsiblePersons.push({
                user,
                userRole: resp.userRole || 'Other',
              })
            }
          })
        }
      } catch (err) {
        console.log(err.response)
      }
    },
    handleOrphanDrugModelValueUpdate(value) {
      this.orphanDrugContent = value
    },
    addResponsiblePerson() {
      this.responsiblePersons.push({
        user: null,
        userRole: 'Other',
      })
    },
    setMainResponsible(index) {
      this.responsiblePersons = this.responsiblePersons.map((person, i) => {
        person.userRole = i === index ? 'Main Responsible' : 'Other'
        return person
      })
    },
    removeResponsiblePerson(index) {
      this.responsiblePersons.splice(index, 1)
    },
    handleUserSelection(value, index) {
      this.responsiblePersons[index].user = value
    },
    async confirmBasicData() {
      const [year, month] = this.dateSubmission.split('-')
      const submissionDateObject = new Date(
        parseInt(year),
        parseInt(month) - 1,
        1,
      )

      const data = {
        assetId: this.id || this.initialData.id,
        assetName: this.assetName,
        dateSubmission: submissionDateObject.toISOString(),
        orphanDrug:
          this.orphanDrugContent === 'Yes'
            ? 1
            : this.orphanDrugContent === 'No'
              ? 0
              : null,
        responsiblePersons: this.responsiblePersons.map((person) => ({
          userId: person.user ? person.user.id : null,
          userRole: person.userRole,
        })),
      }

      this.$emit('confirm', { data, source: 'basic-data' })
    },
  },
}
</script>
