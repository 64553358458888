<template>
  <div class="jca-module neumorphic-container">
    <div class="text-primary text-h4 mb-4 text-center">My Assets</div>
    <div class="select-container mb-4 justify-center">
      <q-select
        v-model="selectedAsset"
        :options="options"
        style="max-width: 300px; width: 100%"
        neumorphic
        dense
        label="Select Asset"
        @update:model-value="handleModelValueUpdate"
        class="q-mr-md custom-dropdown"
        placeholder="Select an Asset"
      />
      <q-btn
        color="primary"
        class="add-new-btn neumorphic-btn"
        @click="handleAddNewAsset"
      >
        Add New Asset
      </q-btn>
    </div>

    <q-tabs v-model="activeTab" dense>
      <q-tab name="overview" label="Asset Overview" />
      <q-tab name="summary" label="Summary" />
    </q-tabs>

    <q-tab-panels v-model="activeTab">
      <q-tab-panel name="overview">
        <AssetOverview
          v-if="showModuleContent"
          :key="key"
          :selectedOption="selectedOption"
          :assetId="assetId"
          :assetName="assetName"
          :picos="picos"
          @confirm="handleConfirm"
        />
      </q-tab-panel>
      <q-tab-panel name="summary">
        <div>Overall Summary: PICOs & Evidence for the Asset</div>
        <!-- Include summary details or component here -->
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script>
import axios from 'axios'
import AssetOverview from './AssetOverview.vue'

export default {
  name: 'updateEgp',
  components: {
    AssetOverview,
  },
  data() {
    return {
      assetId: '',
      assetName: '',
      selectedAsset: null,
      selectedOption: null,
      options: [],
      picos: [],
      activeTab: 'overview', // Default tab
      key: 0,
    }
  },
  methods: {
    async handleAddNewAsset() {
      const assetName = prompt('Enter the new asset name:')
      if (assetName) {
        try {
          const response = await axios.post('/jca/addAsset', { assetName })
          const newAssetId = response.data

          const newAsset = { id: newAssetId, assetName }
          this.options.push({ value: newAsset, label: assetName })
          this.selectedAsset = { value: newAsset, label: assetName }
          this.handleModelValueUpdate(this.selectedAsset)
          this.key++
        } catch (error) {
          console.error('Error adding new asset:', error)
        }
      }
    },
    async handleModelValueUpdate(selectedAsset) {
      if (!selectedAsset || !selectedAsset.value || !selectedAsset.value.id) {
        this.selectedOption = null
        this.picos = []
        this.key++
        return
      }

      this.selectedOption = {
        id: selectedAsset.value.id,
        assetName: selectedAsset.value.assetName,
      }
      this.assetId = selectedAsset.value.id
      this.assetName = selectedAsset.value.assetName
      try {
        const response = await axios.get(
          `jca/getBasicDatasForAsset/${selectedAsset.value.id}`,
        )
        this.backendData = response.data
        const picoResponse = await axios.get('jca/fetchPicos/' + this.assetId)
        this.picos = picoResponse.data
      } catch (error) {
        console.error('Error fetching basic data:', error)
      }
      this.key++
    },
    async handleConfirm({ data, source }) {
      console.log('source: ', source)
      this.$emit('confirm', data)
    },
  },
  computed: {
    isAssetSelected() {
      return this.selectedAsset !== null
    },
    showModuleContent() {
      return this.isAssetSelected
    },
  },
  watch: {
    selectedAsset(newVal) {
      console.log('selectedAsset changed:', newVal)
      // this.handleModelValueUpdate(newVal)
    },
  },
  async mounted() {
    try {
      const response = await axios.get('jca/getAssets')
      this.options = response.data.map((asset) => ({
        value: asset,
        label: asset.assetName,
      }))
    } catch (error) {
      console.error('Error fetching assets:', error)
    }
  },
}
</script>

<style lang="scss">
@import './jcaStyle.scss';
</style>
