<template>
  <div class="intervention-form">
    <div class="form-group">
      <label>Select one of the following options:</label>
      <div class="form-control">
        <div class="radio-group">
          <div class="radio-item">
            <input
              type="radio"
              id="monotherapy-own-drug"
              v-model="intervention"
              value="monotherapyOwnDrug"
              @change="emitData"
            />
            <label for="monotherapy-own-drug">Monotherapy with own drug</label>
          </div>
          <div class="radio-item">
            <input
              type="radio"
              id="monotherapy-background"
              v-model="intervention"
              value="monotherapyBackground"
              @change="emitData"
            />
            <label for="monotherapy-background"
              >Monotherapy with approved background treatment</label
            >
            <input
              v-if="intervention === 'monotherapyBackground'"
              type="text"
              v-model="value1"
              placeholder="Enter approved background treatment(s)"
              class="input-field"
              @input="emitData"
            />
          </div>
          <div class="radio-item">
            <input
              type="radio"
              id="combination-no-background"
              v-model="intervention"
              value="combinationNoBackground"
              @change="emitData"
            />
            <label for="combination-no-background"
              >Therapy in combination with other therapy(ies) without any
              background treatment</label
            >
            <input
              v-if="intervention === 'combinationNoBackground'"
              type="text"
              v-model="value1"
              placeholder="Enter combination therapy(ies)"
              class="input-field"
              @input="emitData"
            />
          </div>
          <div class="radio-item">
            <input
              type="radio"
              id="combination-background"
              v-model="intervention"
              value="combinationBackground"
              @change="emitData"
            />
            <label for="combination-background"
              >Therapy in combination with other therapy(ies) and permitted
              background treatment</label
            >
            <input
              v-if="intervention === 'combinationBackground'"
              type="text"
              v-model="value1"
              placeholder="Enter combination therapy(ies)"
              class="input-field"
              @input="emitData"
            />
            <input
              v-if="intervention === 'combinationBackground'"
              type="text"
              v-model="value2"
              placeholder="Enter permitted background treatment(s)"
              class="input-field"
              @input="emitData"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Navigation Buttons -->
    <div class="form-actions">
      <q-btn label="Back" @click="emitBack" class="q-mt-md" />
      <q-btn label="Next" @click="emitNext" class="q-mt-md" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'InterventionForm',
  props: {
    picoId: {
      type: Number,
      required: true,
    },
    visualizationId: {
      type: Number,
      required: true,
    },
    selectedIntervention: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      intervention: '',
      value1: '',
      value2: '',
    }
  },
  async mounted() {
    if (this.selectedIntervention) {
      this.intervention = this.selectedIntervention.intervention || ''
      this.value1 = this.selectedIntervention.value1 || ''
      this.value2 = this.selectedIntervention.value2 || ''
    }
  },
  methods: {
    emitData() {
      const interventionData = {
        picoId: this.picoId,
        intervention: this.intervention,
        value1: this.value1,
        value2: this.value2,
      }

      this.$emit('update:intervention', interventionData)
    },
    emitBack() {
      this.$emit('navigate', 'population')
    },
    emitNext() {
      this.$emit('navigate', 'comparison')
    },
  },
  watch: {
    intervention(newVal) {
      console.log('Intervention changed:', newVal)
      this.emitData()
    },
    value1(newVal) {
      console.log('Value1 changed:', newVal)
      this.emitData()
    },
    value2(newVal) {
      console.log('Value2 changed:', newVal)
      this.emitData()
    },
  },
}
</script>
