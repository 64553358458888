<template>
  <div class="comparison-group-form">
    <div class="form-groups-container">
      <div class="form-group">
        <label>Select Comparator:</label>
        <q-table
          :rows="comparisionGroupOptions"
          :columns="comparisonColumns"
          row-key="id"
          class="q-mt-md"
        >
          <template v-slot:body-cell-selected="props">
            <q-td>
              <q-radio v-model="selectedComparator" :val="props.row" />
            </q-td>
          </template>
          <template v-slot:body-cell-label="props">
            <q-td>{{ props.row.label }}</q-td>
          </template>
          <template v-slot:body-cell-multipleComparators="props">
            <q-td>{{
              props.row.value.multipleComparators ? 'Yes' : 'No'
            }}</q-td>
          </template>
        </q-table>
      </div>

      <div class="form-group-container">
        <div class="form-group">
          <label>Add Comparator: </label>
          <p></p>
          <label>Comparator Treatments:</label>
          <p class="info-text">
            The comparators entered are defined as equivalent alternatives for
            this specific PICO:
          </p>
          <div
            v-for="(comparator, index) in comparators"
            :key="index"
            class="comparator-group"
          >
            <div class="comparator-header">
              <label>Comparator {{ index + 1 }}:</label>
              <q-btn
                v-if="comparators.length > 1"
                flat
                round
                color="red"
                icon="close"
                size="sm"
                @click="removeComparator(index)"
              />
            </div>
            <div class="form-control">
              <input
                type="text"
                v-model="comparator.combinations[0]"
                placeholder="Enter treatment"
                class="input-field"
              />
              <button @click="addCombination(index)" class="btn-add">
                Add another combination
              </button>
            </div>
            <div
              v-for="(
                combination, combinationIndex
              ) in comparator.combinations.slice(1)"
              :key="combinationIndex"
              class="combination-treatment"
            >
              <input
                type="text"
                v-model="comparator.combinations[combinationIndex + 1]"
                placeholder="Enter combination treatment"
                class="input-field"
              />
              <q-btn
                flat
                round
                color="red"
                icon="close"
                size="sm"
                @click="removeCombination(index, combinationIndex + 1)"
              />
            </div>
          </div>
          <button @click="addComparator" class="btn-add">Add Treatment</button>
        </div>

        <div class="form-group multiple-comparators-group">
          <label>Do you need multiple comparators for this population?</label>
          <div class="form-control">
            <div class="radio-group">
              <div class="radio-item">
                <input
                  type="radio"
                  id="single-comparator"
                  v-model="multipleComparators"
                  :value="false"
                />
                <label for="single-comparator"
                  >Only one of the comparators listed above is required for the
                  JCA</label
                >
              </div>
              <div class="radio-item">
                <input
                  type="radio"
                  id="multiple-comparators"
                  v-model="multipleComparators"
                  :value="true"
                />
                <label for="multiple-comparators"
                  >All comparator treatments listed above are required for the
                  JCA</label
                >
              </div>
            </div>
          </div>
          <q-btn
            label="Confirm Comparator"
            @click="confirmAction"
            class="q-mt-md"
            color="primary"
          />
        </div>
      </div>
    </div>

    <div class="form-actions">
      <q-btn label="Back" @click="emitBack" class="q-mt-md" />
      <q-btn label="Next" @click="emitNext" class="q-mt-md" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ComparisonGroupForm',
  props: {
    assetId: {
      type: String,
      required: true,
    },
    picoId: {
      type: Number,
      required: true,
    },
    visualizationId: {
      type: Number,
      required: true,
    },
    comparisonGroup: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedComparator: null,
      comparisionGroupOptions: [],
      comparators: [{ combinations: [''] }],
      multipleComparators: null,
    }
  },
  async mounted() {
    await this.fetchComparisionDatas()
    if (this.comparisonGroup) {
      // Find the matching option in comparisionGroupOptions
      const matchingOption = this.comparisionGroupOptions.find(
        (option) => option.value.id === this.comparisonGroup.id,
      )

      if (matchingOption) {
        this.selectedComparator = matchingOption // Set the selectedComparator to the correct structure
      } else {
        this.selectedComparator = null // No matching option found
      }
    }
  },
  computed: {
    selectLabel() {
      if (this.comparisionGroupOptions.length === 0) {
        return 'No Comparators Available'
      } else {
        return 'Select Comparator'
      }
    },
    comparisonColumns() {
      return [
        {
          name: 'selected',
          label: 'Select',
          align: 'left',
          sortable: false,
        },
        {
          name: 'label',
          label: 'Comparator Group',
          align: 'left',
          field: 'label',
        },
        {
          name: 'multipleComparators',
          label: 'Multiple Comparators',
          align: 'left',
          field: 'multipleComparators',
        },
      ]
    },
  },
  methods: {
    emitBack() {
      this.$emit('navigate', 'intervention') // Change 'comparison' to the desired previous tab name
    },
    // Emit next event
    emitNext() {
      this.$emit('navigate', 'outcome') // Change 'intervention' to the desired next tab name
    },

    async fetchComparisionDatas() {
      try {
        const response = await axios.get(
          'jca/getComparisionDatas/' + this.assetId,
        )
        this.backendData = response.data
        const groupedData = this.backendData.reduce((acc, item) => {
          const key = item.id
          if (!acc[key]) {
            acc[key] = {
              id: key,
              combinations: {},
              multipleComparators: item.multipleComparators,
            }
          }

          // Process each combination
          item.combination.forEach((combo) => {
            const comparatorKey = combo.comparator
            if (!acc[key].combinations[comparatorKey]) {
              acc[key].combinations[comparatorKey] = []
            }
            acc[key].combinations[comparatorKey].push(...combo.combinations)
          })

          return acc
        }, {})

        // Convert the grouped data into the desired format
        this.comparisionGroupOptions = Object.values(groupedData).map(
          (group) => {
            const combinationLabels = Object.keys(group.combinations).map(
              (comparatorKey) => {
                const combos = group.combinations[comparatorKey]
                return combos.join(' AND ') // Join combinations with the same comparator with 'AND'
              },
            )

            return {
              value: group,
              label: combinationLabels.join(' OR '), // Join different comparators with 'OR'
            }
          },
        )
      } catch (error) {
        console.error('Error fetching comparison data:', error)
      }
    },
    async confirmAction() {
      if (this.multipleComparators === null) {
        return
      }
      const comparisonGroupData = {
        id: this.selectedComparator?.id || -1,
        picoId: this.picoId,
        assetId: this.assetId,
        combination: this.comparators,
        multipleComparators: this.multipleComparators,
      }
      this.$emit('update:comparisonGroup', comparisonGroupData)
      // Add a timeout before calling fetchComparisionDatas
      await new Promise((resolve) => setTimeout(resolve, 1000)) // 1000 ms = 1 second

      await this.fetchComparisionDatas()
    },
    addComparator() {
      this.comparators.push({ combinations: [''] })
    },
    removeComparator(index) {
      this.comparators.splice(index, 1)
    },
    addCombination(index) {
      this.comparators[index].combinations.push('')
    },
    removeCombination(comparatorIndex, combinationIndex) {
      this.comparators[comparatorIndex].combinations.splice(combinationIndex, 1)
    },
  },
  watch: {
    comparators: {
      handler: 'emit',
      deep: true,
    },
    selectedComparator(newValue) {
      if (newValue) {
        this.$emit('select-comparator', newValue) // Emit the event with the new value
      }
    },
    multipleComparators(newVal) {
      console.log('Multiple comparators:', newVal)
    },
    comparisonGroup: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.comparators = newVal.combination || [{ combinations: [''] }]
          this.multipleComparators = newVal.multipleComparators || false
        }
      },
    },
  },
}
</script>

<style scoped>
.comparison-group-form {
  /* Add your styles */
}

.form-groups-container {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping to keep items in one row */
}

.form-group {
  flex: 1; /* Allows each group to take equal space */
  margin-right: 20px; /* Space between groups */
  min-width: 300px; /* Ensures a minimum width for responsiveness */
}

.form-group:last-child {
  margin-right: 0; /* Remove margin from the last group */
}

.form-group-container {
  display: flex;
  flex-direction: column; /* Stacks the Comparator Treatments and Multiple Comparators vertically */
  flex: 1; /* Allows this group to take the remaining space */
}

.comparator-group {
  margin-bottom: 15px;
}

/* Add other existing styles */
</style>
