<template>
  <div>
    <button @click="generatePICOConsolidation">
      Generate PICO Consolidation
    </button>
    <div
      v-if="picoConsolidation && picoConsolidation.length > 0"
      class="output"
    >
      <h4>PICO Consolidation</h4>
      <q-table
        :rows="picoConsolidation"
        :columns="columns"
        row-key="picoNumber"
        virtual-scroll
        :virtual-scroll-sticky-size-start="48"
      >
        <template v-slot:body-cell-outcomes="props">
          <q-td :props="props">
            <ul>
              <li v-for="outcome in props.value" :key="outcome">
                {{ outcome }}
              </li>
            </ul>
          </q-td>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'PICOConsolidation',
  props: {
    assetId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      picoConsolidation: [],
      consolidationHistory: [],
      isAdmin: false,
      columns: [
        {
          name: 'picoNumber',
          label: 'PICO No.',
          field: 'picoNumber',
          align: 'left',
        },
        {
          name: 'population',
          label: 'Population',
          field: 'population',
          align: 'left',
        },
        {
          name: 'intervention',
          label: 'Intervention',
          field: 'intervention',
          align: 'left',
        },
        {
          name: 'comparator',
          label: 'Comparator',
          field: 'comparator',
          align: 'left',
        },
        {
          name: 'outcomes',
          label: 'Outcome(s)',
          field: 'outcomes',
          align: 'left',
        },
        {
          name: 'relevantCountries',
          label: 'Relevant Countr(y)ies',
          field: 'relevantCountries',
          align: 'left',
          format: (val) => val.join(', '),
        },
        {
          name: 'relevantPopulationPercentage',
          label: 'Relevant for % of EU Population',
          field: 'relevantPopulationPercentage',
          align: 'left',
          format: (val) => `${val}%`,
        },
        {
          name: 'populationBasedRelevance',
          label: 'Population-based Relevance',
          field: 'populationBasedRelevance',
          align: 'left',
        },
      ],
    }
  },
  methods: {
    async generatePICOConsolidation() {
      try {
        const picoResponse = await axios.get(`jca/fetchPicos/${this.assetId}`)
        var picos = picoResponse.data

        const groupedByCombinations = picos.reduce((acc, curr) => {
          const vizId = curr.visualizationId
          if (!acc[vizId]) {
            acc[vizId] = {
              combinations: [],
              intervention: curr.intervention,
              outcomes: curr.outcomes,
              country: curr.country,
              population: curr.population,
              description: curr.description,
              multipleComparatorId: curr.multipleComparatorId,
              assetId: curr.assetId,
              id: curr.id,
              visualizationId: curr.visualizationId,
            }
          }
          acc[vizId].combinations.push(curr.combinations)
          return acc
        }, {})

        // Merge PICOs based on the specified rules
        const mergedPicos = this.mergePicos(
          Object.values(groupedByCombinations),
        )

        // Transform the merged data to the desired structure
        this.picoConsolidation = mergedPicos.map((pico, index) => ({
          picoNumber: index + 1,
          population: pico.population,
          intervention: pico.intervention,
          comparator: pico.combinations.join(', '),
          outcomes: pico.outcomes,
          relevantCountries: pico.relevantCountries,
          relevantPopulationPercentage: this.calculatePopulationPercentage(
            pico.relevantCountries,
          ),
          populationBasedRelevance: this.getPopulationBasedRelevance(
            this.calculatePopulationPercentage(pico.relevantCountries),
          ),
        }))

        this.saveConsolidationVersion(this.picoConsolidation)
      } catch (error) {
        console.error('Error fetching PICOs:', error)
      }
    },

    mergePicos(picos) {
      const merged = {}
      const combinationCounts = {}
      const combinationCountries = {}

      // Count occurrences of each combination and track countries
      picos.forEach((pico) => {
        pico.combinations.forEach((combination) => {
          combinationCounts[combination] =
            (combinationCounts[combination] || 0) + 1
          if (!combinationCountries[combination]) {
            combinationCountries[combination] = new Set()
          }
          combinationCountries[combination].add(pico.country)
        })
      })

      picos.forEach((pico) => {
        let processedCombinations = [...pico.combinations]

        if (pico.multipleComparatorId === 0) {
          // Keep the most frequent combination and track its countries
          const mostFrequent = processedCombinations.reduce((a, b) =>
            combinationCounts[a] > combinationCounts[b] ? a : b,
          )
          processedCombinations = [mostFrequent]

          // Add relevant countries from the most frequent combination
          pico.relevantCountries = Array.from(
            combinationCountries[mostFrequent],
          )
        } else if (pico.multipleComparatorId === 1) {
          // For each combination, create a new PICO entry
          processedCombinations.forEach((combination) => {
            const key = `${pico.population}|${pico.intervention}|${combination}`
            if (!merged[key]) {
              merged[key] = {
                ...pico,
                combinations: [combination], // Only include the current combination
                outcomes: pico.outcomes ? pico.outcomes.split(', ') : [],
                relevantCountries: Array.from(
                  combinationCountries[combination],
                ),
              }
            } else {
              // Merge outcomes and relevant countries
              merged[key].outcomes = [
                ...new Set([
                  ...merged[key].outcomes,
                  ...(pico.outcomes ? pico.outcomes.split(', ') : []),
                ]),
              ]
              merged[key].relevantCountries = [
                ...new Set([
                  ...merged[key].relevantCountries,
                  ...Array.from(combinationCountries[combination]),
                ]),
              ]
            }
          })
        }

        // If multipleComparatorId is not 1, process normally
        if (pico.multipleComparatorId !== 1) {
          const key = `${pico.population}|${pico.intervention}|${processedCombinations}`
          if (!merged[key]) {
            merged[key] = {
              ...pico,
              combinations: processedCombinations,
              outcomes: pico.outcomes ? pico.outcomes.split(', ') : [],
            }
          } else {
            // Merge outcomes and relevant countries
            merged[key].outcomes = [
              ...new Set([
                ...merged[key].outcomes,
                ...(pico.outcomes ? pico.outcomes.split(', ') : []),
              ]),
            ]
            merged[key].relevantCountries = [
              ...new Set([
                ...merged[key].relevantCountries,
                ...pico.relevantCountries,
              ]),
            ]
          }
        }
      })

      return Object.values(merged)
    },
    calculatePopulationPercentage(countries) {
      // Implement logic to calculate the population percentage based on the countries
      // Replace this with your actual implementation
      console.log('Calculating population percentage for countries:', countries)
      return 20 // Dummy value for demonstration
    },
    saveConsolidationVersion(data) {
      const currentDate = new Date().toLocaleString()
      this.consolidationHistory.push({ date: currentDate, data })
    },
    viewConsolidation(data) {
      this.picoConsolidation = data
    },
    deleteVersion(index) {
      if (this.isAdmin) {
        this.consolidationHistory.splice(index, 1)
      } else {
        console.log(
          'You do not have permission to delete consolidation versions.',
        )
      }
    },
    getPopulationBasedRelevance(percentage) {
      if (percentage > 10) {
        return 'high'
      } else if (percentage >= 3 && percentage <= 10) {
        return 'medium'
      } else {
        return 'low'
      }
    },
  },
}
</script>
