<template>
  <q-drawer class="bg-accent" v-model="leftDrawerOpen" show-if-above bordered>
    <q-list>
      <q-item-label header></q-item-label>
      <q-item clickable v-ripple @click="goToOverview" class="menu-item">
        <q-item-section avatar>
          <q-icon color="primary" name="home" />
        </q-item-section>
        <q-item-section class="menu-text">
          <div class="text-primary">Overview</div>
        </q-item-section>
      </q-item>
      <q-item clickable v-ripple @click="goToNewEgp" class="menu-item">
        <q-item-section avatar>
          <q-icon color="primary" name="add_chart" />
        </q-item-section>
        <q-item-section class="menu-text">
          <div class="text-primary">Generate new EGP</div>
        </q-item-section>
      </q-item>
      <q-item clickable v-ripple @click="goToUpdateEgp" class="menu-item">
        <q-item-section avatar>
          <q-icon color="primary" name="update" />
        </q-item-section>
        <q-item-section class="menu-text">
          <div class="text-primary">Manage EGPs</div>
        </q-item-section>
      </q-item>
      <q-item clickable v-ripple @click="goToJcaModule" class="menu-item">
        <q-item-section avatar>
          <q-icon color="primary" name="update" />
        </q-item-section>
        <q-item-section class="menu-text">
          <div class="text-primary">JCA Module</div>
        </q-item-section>
      </q-item>
    </q-list>
    <div class="q-pa-md fixed-bottom-left">
      <q-btn dense rounded class="" @click="onSupport">
        <span class="material-icons">help</span>
        <div class="q-pa-md text-primary">Technical support</div>
      </q-btn>
    </div>
  </q-drawer>
</template>

<style scoped>
.menu-item {
  transition:
    background-color 0.3s,
    border-left-color 0.3s;
  border-left: 3px solid transparent;
  padding-left: 12px;
}

.menu-item:hover {
  background-color: var(--q-color-primary);
  border-left-color: #ff7500;
}

.menu-icon {
  display: none;
  /* Den Icon-Teil standardmäßig ausblenden */
}

.menu-text {
  color: black;
}

.q-drawer[dir='rtl'] .menu-icon {
  display: block;
}

.material-icons {
  font-size: 30px;
  color: #ff7500;
}
</style>

<script setup>
import router from '@/router'
import store from '@/store.js'
import { computed } from 'vue'

const leftDrawerOpen = computed(() => store.state.leftDrawerOpen)

const goToOverview = async () => {
  router.push('/')
}

const goToNewEgp = async () => {
  router.push('/newEgp')
}

const goToUpdateEgp = async () => {
  router.push('/updateEgp')
}

const goToJcaModule = async () => {
  router.push('/jca')
}

const onSupport = async () => {
  const emailAddress = 'axel.dittmar@ipam-wismar.de'
  const subject = 'Technische Support-Anfrage'
  const username = store.state.user.username // get the username from the Vuex store
  const message = `Dear EVIGATOR support team,\n\n${username} requests technical support for his/her EVIGATOR account.`
  const mailToLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`
  window.location.href = mailToLink
}
</script>
