<template>
  <div>
    <q-btn
      label="Select PICO from another country"
      @click="showCountrySelector = true"
    />

    <q-dialog v-model="showCountrySelector">
      <q-card>
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Select Country</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section>
          <q-select
            v-model="selectedCountry"
            :options="uniqueCountries"
            label="Select a country"
            @update:model-value="handleCountryChange"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="showPICOSelector">
      <q-card>
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Select PICO</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section>
          <q-table
            :rows="picosForSelectedCountry"
            :columns="columns"
            row-key="picoNumber"
            virtual-scroll
            :virtual-scroll-sticky-size-start="48"
          >
            <template v-slot:body-cell="props">
              <q-td :props="props" style="white-space: pre-line">
                <div v-html="props.value"></div>
              </q-td>
            </template>

            <template v-slot:body-cell-actions="props">
              <q-td>
                <q-btn flat dense icon="edit" @click="selectPICO(props.row)">
                  Select
                </q-btn>
              </q-td>
            </template>
          </q-table>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  name: 'PICOSelector',
  props: {
    picos: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showCountrySelector: false,
      showPICOSelector: false,
      selectedCountry: null,
      columns: [
        {
          name: 'picoNumber',
          label: 'PICO No.',
          field: 'picoNumber',
          align: 'left',
        },
        { name: 'country', label: 'Country', field: 'country', align: 'left' },
        {
          name: 'population',
          label: 'Population',
          field: 'population',
          align: 'left',
        },
        {
          name: 'combinations',
          label: 'Comparator',
          field: 'combinations',
          align: 'left',
        },
        {
          name: 'intervention',
          label: 'Intervention',
          field: 'intervention',
          align: 'left',
        },
        {
          name: 'outcomes',
          label: 'Outcome(s)',
          field: 'outcomes',
          align: 'left',
        },
        {
          name: 'actions',
          label: 'Actions',
          field: 'actions',
          align: 'center',
        },
      ],
    }
  },
  computed: {
    uniqueCountries() {
      return [...new Set(this.picos.map((pico) => pico.country))]
    },
    picosForSelectedCountry() {
      return this.selectedCountry
        ? this.picos
            .filter((pico) => pico.country === this.selectedCountry)
            .map((pico, index) => ({
              ...pico,
              picoNumber: index + 1, // Assign picoNumber based on index
            }))
        : []
    },
  },
  methods: {
    handleCountryChange(value) {
      this.selectedCountry = value
      this.showPICOSelector = true
      this.showCountrySelector = false
    },
    selectPICO(pico) {
      this.$emit('pico-selected', pico)
      this.showPICOSelector = false
      this.showCountrySelector = false // Close the country selector as well
    },
  },
}
</script>
