<template>
  <div class="specify-outcome-form">
    <div class="form-group">
      <label>List of defined outcomes:</label>
      <table>
        <thead>
          <tr>
            <th>Select</th>
            <th>Name</th>
            <th>Category</th>
            <th>Outcome Measure</th>
            <th>Time of Assessment</th>
            <th>Statistical Measurement</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(outcome, index) in localOutcomes" :key="index">
            <td>
              <input
                type="checkbox"
                v-model="outcome.selected"
                @change="handleOutcomeSelection(outcome)"
              />
            </td>
            <td>{{ outcome.name }}</td>
            <td>{{ outcome.category }}</td>
            <td>{{ outcome.outcomeMeasure }}</td>
            <td>{{ outcome.timeAssessment }}</td>
            <td>{{ outcome.statisticalMeasurement }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="form-actions">
      <button @click="showOutcomeModal = true" class="btn-add">
        Add New Outcome
      </button>
      <div class="form-actions">
        <q-btn label="Back" @click="emitBack" class="q-mt-md" />
        <q-btn label="Next" @click="emitNext" class="q-mt-md" />
      </div>
    </div>

    <OutcomeModal
      v-if="showOutcomeModal"
      :selectedOutcomeOptions="selectedOutcomeOptions"
      @close="showOutcomeModal = false"
      @addOutcome="addOutcome"
    />
  </div>
</template>

<script>
import axios from 'axios'
import OutcomeModal from './OutcomeModal.vue'

export default {
  name: 'OutcomeForm',
  components: {
    OutcomeModal,
  },
  props: {
    assetId: { type: String, required: true },
    picoId: { type: Number, required: true },
    visualizationId: { type: Number, required: true },
    outcomes: { type: Array, required: true },
  },
  data() {
    return {
      showOutcomeModal: false,
      selectedOutcomeOptions: [],
      localOutcomes: [],
    }
  },
  async mounted() {
    await this.fetchOutcomesData()
    this.initializeSelectedOutcomes()
    await this.fetchVisualizationData()
  },

  methods: {
    initializeSelectedOutcomes() {
      // Set selected state based on the outcomes prop
      this.localOutcomes.forEach((localOutcome) => {
        const found = this.outcomes.find(
          (outcome) => outcome.id === localOutcome.id,
        )
        if (found) {
          localOutcome.selected = true // Mark as selected if it exists in outcomes
        }
      })
    },
    async fetchVisualizationData() {
      if (this.visualizationId) {
        try {
          const response = await axios.get(
            `jca/getOutcomeDataForVisualizationId/${this.visualizationId}`,
          )
          this.outcomesForVisualizationId = response.data.map((item) => ({
            id: item.id || -1,
            picoId: this.picoId,
            assetId: item.assetId,
            name: item.name || '',
            category: this.mapCategoryToDropdownValue(item.category) || '',
            outcomeMeasure: item.outcomeMeasure || '',
            timeAssessment: item.timeAssessment || '',
            statisticalMeasurement: item.statisticalMeasurement || '',
            selected: true, // Mark as selected
          }))

          this.localOutcomes.forEach((localOutcome) => {
            const found = this.outcomesForVisualizationId.find(
              (outcome) => outcome.id === localOutcome.id,
            )
            if (found) {
              localOutcome.selected = true // Mark as selected if it exists in outcomes
            }
          })
        } catch (error) {
          console.error('Error fetching outcome data:', error)
        }
      }
    },
    async fetchOutcomesData() {
      try {
        const response = await axios.get(`jca/getOutcomeDatas/${this.assetId}`)
        const data = response.data

        this.localOutcomes = data.map((item) => ({
          id: item.id || -1,
          assetId: item.assetId,
          picoId: this.picoId,
          name: item.name || '',
          category: this.mapCategoryToDropdownValue(item.category) || '',
          outcomeMeasure: item.outcomeMeasure || '',
          timeAssessment: item.timeAssessment || '',
          statisticalMeasurement: item.statisticalMeasurement || '',
          selected: false, // Default to false initially
        }))
      } catch (error) {
        console.error('Error fetching outcome data:', error)
      }
    },
    emitBack() {
      this.$emit('navigate', 'comparison')
    },
    emitNext() {
      this.$emit('navigate', 'population')
    },
    emitOutcomesData() {
      this.$emit('update:outcomes', this.localOutcomes)
    },
    handleOutcomeSelection(outcome) {
      console.log('Outcome selected:', outcome)
      this.$emit(
        'outcome-selected',
        this.localOutcomes.filter((o) => o.selected),
      )
    },
    handleModelValueUpdate(value) {
      if (value.value === null) {
        this.localOutcomes = [
          {
            id: -1,
            picoId: this.picoId,
            assetId: this.assetId,
            name: '',
            category: '',
            outcomeMeasure: '',
            timeAssessment: '',
            statisticalMeasurement: '',
          },
        ]
      } else {
        const selectedOutcome = value.value
        this.localOutcomes = [
          {
            id: selectedOutcome.id || -1,
            picoId: this.picoId,
            assetId: this.assetId,
            name: selectedOutcome.name || '',
            category:
              this.mapCategoryToDropdownValue(selectedOutcome.category) || '',
            outcomeMeasure: selectedOutcome.outcomeMeasure || '',
            timeAssessment: selectedOutcome.timeAssessment || '',
            statisticalMeasurement:
              selectedOutcome.statisticalMeasurement || '',
          },
        ]
      }
    },
    mapCategoryToDropdownValue(category) {
      const categoryMapping = {
        mortality: 'mortality',
        morbidity: 'morbidity',
        hrqol: 'hrqol',
        safety: 'safety',
        other: 'other',
      }

      return categoryMapping[category.toLowerCase()] || ''
    },
    addOutcome(newOutcome) {
      newOutcome.picoId = this.picoId
      newOutcome.assetId = this.assetId
      this.localOutcomes.push(newOutcome)
      this.emitOutcomesData()
      this.showOutcomeModal = false
    },
  },
}
</script>

<style scoped>
.specify-outcome-form {
  /* Add your styles */
}

.form-group {
  margin-bottom: 20px;
}

.btn-add {
  margin-right: 10px;
}
</style>
