<template>
  <div>
    <q-btn
      label="Generate PICO Overview per Country"
      @click="showCountrySelector = true"
    />

    <q-dialog v-model="showCountrySelector">
      <q-card>
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Select Country</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section>
          <q-select
            v-model="selectedCountry"
            :options="uniqueCountries"
            label="Select a country"
            @update:model-value="handleCountryChange"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <div v-if="selectedCountry" class="output">
      <h4>PICO Overview for {{ selectedCountry }}</h4>
      <div v-if="picosForSelectedCountry.length > 0">
        <h5>Evidence needs in {{ selectedCountry }}</h5>
        <q-table
          :rows="picosForSelectedCountry"
          :columns="columns"
          row-key="picoNumber"
          virtual-scroll
          :virtual-scroll-sticky-size-start="48"
        >
          <template v-slot:body-cell="props">
            <q-td :props="props" style="white-space: pre-line">
              <div v-html="props.value"></div>
            </q-td>
          </template>
        </q-table>
      </div>
      <div v-else>
        <p>No PICOs available for {{ selectedCountry }}.</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'PICOOverview',
  props: {
    assetId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      picoOverview: null,
      showCountrySelector: false,
      selectedCountry: null,
      columns: [
        {
          name: 'picoNumber',
          label: 'PICO No.',
          field: 'picoNumber',
          align: 'left',
        },
        {
          name: 'population',
          label: 'Population',
          field: 'population',
          align: 'left',
        },
        {
          name: 'combinations',
          label: 'Comparator',
          field: 'combinations',
          align: 'left',
        },
        {
          name: 'intervention',
          label: 'Intervention',
          field: 'intervention',
          align: 'left',
        },
        {
          name: 'outcomes',
          label: 'Outcome(s)',
          field: 'outcomes',
          align: 'left',
        },
      ],
    }
  },
  computed: {
    uniqueCountries() {
      return this.picoOverview
        ? [...new Set(this.picoOverview.map((data) => data.country))]
        : []
    },
    picosForSelectedCountry() {
      if (!this.selectedCountry || !this.picoOverview) return []
      const countryData = this.picoOverview.find(
        (data) => data.country === this.selectedCountry,
      )
      return countryData ? countryData.picos : []
    },
  },
  mounted() {
    this.generatePICOOverview()
  },
  methods: {
    async generatePICOOverview() {
      try {
        const picoResponse = await axios.get(`jca/fetchPicos/${this.assetId}`)
        const picos = picoResponse.data
        console.log('picos', picos)
        // Group PICOs by country
        const groupedPicos = picos.reduce((acc, pico) => {
          const {
            country,
            description,
            population,
            intervention,
            outcomes,
            combinations,
          } = pico

          if (!acc[country]) {
            acc[country] = {
              country,
              picos: [
                {
                  picoNumber: 1,
                  description,
                  population,
                  intervention,
                  outcomes,
                  combinations,
                },
              ],
            }
          } else {
            acc[country].picos.push({
              picoNumber: acc[country].picos.length + 1,
              description,
              population,
              intervention,
              outcomes,
              combinations,
            })
          }

          return acc
        }, {})

        // Convert the grouped object to an array
        this.picoOverview = Object.values(groupedPicos)
      } catch (error) {
        console.error('Error fetching PICOs:', error)
      }
    },
    handleCountryChange(value) {
      this.selectedCountry = value
      this.showCountrySelector = false
    },
  },
}
</script>
