<template>
  <div class="pico-form neumorphic-container">
    <div class="header-container">
      <h5 class="define-pico-header">Define a new PICO</h5>
      <PICOSelector
        :picos="picos"
        @pico-selected="handlePicoSelection"
        class="pico-selector"
      />
    </div>
    <!-- Display the selected country label here -->
    <div v-if="selectedCountry" class="selected-country-label">
      Selected Country: {{ selectedCountry }}
    </div>
    <!-- Display the selected population label here -->
    <div v-if="population" class="selected-country-label">
      Selected Population: {{ population.description }}
    </div>
    <div v-if="intervention" class="selected-country-label">
      Selected Intervention:
      {{ intervention.value1 + ' ' + intervention.value2 }}

      <!-- Conditionally display the asset if the condition is met -->
      <span v-if="intervention.intervention === 'monotherapyOwnDrug'">
        Asset: {{ assetName }}
      </span>
    </div>

    <q-tabs v-model="activeTab" dense>
      <q-tab name="country" label="Select Country" />
      <q-tab name="population" label="Defined Populations" />
      <q-tab name="intervention" label="Intervention" />
      <q-tab name="comparison" label="Control Arm" />
      <q-tab name="outcome" label="Specify Outcome(s)" />
    </q-tabs>

    <q-tab-panels v-model="activeTab">
      <q-tab-panel name="country">
        <div class="neumorphic-dropdown">
          <div class="country-selector">
            <label for="country-dropdown">Select a country:</label>
            <select
              id="country-dropdown"
              v-model="selectedCountry"
              @change="handleCountrySelection"
              class="neumorphic-select"
            >
              <option value="" disabled selected>Select a country</option>
              <option
                v-for="country in euCountries"
                :key="country"
                :value="country"
              >
                {{ country }}
              </option>
            </select>
          </div>
        </div>
      </q-tab-panel>

      <q-tab-panel name="population">
        <PopulationForm
          :picoId="id"
          :assetId="assetId"
          :visualizationId="visualizationId"
          :population="population"
          @select-population="handlePopulationSelection"
          @update:population="updatePopulation"
          @navigate="handleTabNavigation"
        />
      </q-tab-panel>

      <q-tab-panel name="intervention">
        <InterventionForm
          :picoId="id"
          :visualizationId="visualizationId"
          :selectedIntervention="intervention"
          @update:intervention="updateIntervention"
          @navigate="handleTabNavigation"
        />
      </q-tab-panel>

      <q-tab-panel name="comparison">
        <ComparisonGroupForm
          :visualizationId="visualizationId"
          :picoId="id"
          :assetId="assetId"
          :comparisonGroup="comparisonGroup"
          @select-comparator="handleComparatorSelection"
          @update:comparisonGroup="updateComparisonGroup"
          @navigate="handleTabNavigation"
        />
      </q-tab-panel>

      <q-tab-panel name="outcome">
        <OutcomeForm
          :picoId="id"
          :assetId="assetId"
          :visualizationId="visualizationId"
          :outcomes="outcome"
          @update:outcomes="updateOutcomes"
          @navigate="handleTabNavigation"
          @outcome-selected="updateSelectedOutcomes"
        />
      </q-tab-panel>
    </q-tab-panels>
    <!-- Missing Fields Message -->
    <div v-if="missingFields.length > 0" class="missing-fields">
      Please fill in the following fields:
      <ul>
        <li v-for="field in missingFields" :key="field">{{ field }}</li>
      </ul>
    </div>

    <div class="form-actions">
      <button
        @click="saveNewPICO"
        class="neumorphic-btn"
        :disabled="!isPicoComplete"
      >
        Save PICO as new
      </button>
      <button
        @click="savePICO"
        class="neumorphic-btn"
        :disabled="!isPicoComplete"
      >
        Save PICO
      </button>
      <button @click="cancelPICO" class="neumorphic-btn">Cancel</button>
    </div>
    <div
      v-if="saveFeedback.message"
      :class="['feedback-message', saveFeedback.type]"
    >
      {{ saveFeedback.message }}
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ComparisonGroupForm from './ComparisonGroupForm.vue'
import InterventionForm from './InterventionForm.vue'
import OutcomeForm from './OutcomeForm.vue'
import PICOSelector from './PICOSelector.vue'
import PopulationForm from './PopulationForm.vue'

export default {
  name: 'PICOForm',
  components: {
    PopulationForm,
    InterventionForm,
    ComparisonGroupForm,
    OutcomeForm,
    PICOSelector,
  },
  props: {
    jcaId: {
      type: Number,
      required: true,
    },
    assetId: {
      type: Number,
      required: true,
    },
    assetName: {
      type: String,
      required: true,
    },
    picos: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localJcaId: this.jcaId,
      saveFeedback: {
        message: '',
        type: '', // 'success' or 'error'
      },
      activeTab: 'country', // Default active tab
      euCountries: [],
      selectedCountry: '',
      population: {
        disease: '',
        diseaseCharacteristics: '',
        sociodemographicRestrictions: '',
        previousTreatments: '',
        otherCriteria: '',
        description: '',
        selected: false,
      },
      intervention: null,
      comparisonGroup: null,
      outcome: [
        {
          id: -1,
          picoId: '',
          assetId: -1,
          name: '',
          category: '',
          outcomeMeasure: '',
          timeAssessment: '',
          statisticalMeasurement: '',
          selected: false,
        },
      ],
      currentPopulationId: null,
      currentInterventionId: null,
      currentComparisonGroupId: [],
      currentOutcomeIds: [],
      isExpanded: {
        'select-country': false,
        'expand-population': false,
        'expand-intervention': false,
        'comparison-group': false,
        'specify-outcome': false,
      },
      sectionSummaries: {},
    }
  },
  computed: {
    visualizationId() {
      return this.selectedPico ? this.selectedPico.visualizationId : null
    },
    isPicoComplete() {
      return (
        this.currentPopulationId !== null &&
        this.currentInterventionId !== null &&
        this.currentComparisonGroupId !== null &&
        this.currentOutcomeIds.length > 0
      )
    },
    missingFields() {
      const messages = []
      if (!this.selectedCountry) {
        messages.push('Country')
      }
      if (!this.currentPopulationId) {
        messages.push('Population')
      }
      if (!this.currentInterventionId) {
        messages.push('Intervention')
      }
      if (this.currentComparisonGroupId.length === 0) {
        messages.push('Comparison Group')
      }
      if (this.currentOutcomeIds.length === 0) {
        messages.push('Outcome(s)')
      }
      return messages
    },
  },
  methods: {
    handleTabNavigation(tabName) {
      this.activeTab = tabName // Switch to the specified tab
    },
    async updatePopulation(population) {
      this.population = population
      if (this.population.id !== -1) {
        this.currentPopulationId = this.population.id
      }
      try {
        await axios.post('jca/savePopulation', [this.population])
        this.currentPopulationId = this.population.id // Assuming this is returned
      } catch (error) {
        console.error('Error saving population:', error)
      }
    },

    async updateIntervention(intervention) {
      this.intervention = intervention
      try {
        const saveInterventionResult = await axios.post(
          'jca/saveIntervention',
          this.intervention,
        )
        this.currentInterventionId = saveInterventionResult.data // Assuming this is returned
      } catch (error) {
        console.error('Error saving intervention:', error)
      }
    },

    async updateComparisonGroup(comparisonGroup) {
      this.comparisonGroup = comparisonGroup
      const saveComparisonGroupResult = await axios.post(
        'jca/saveComparisonGroup',
        this.comparisonGroup,
      )
      this.currentComparisonGroupId = saveComparisonGroupResult.data

      const flattenCombinations = (combinations) => {
        return combinations.flatMap((item) => {
          if (Array.isArray(item.combinations)) {
            return item.combinations
          } else if (typeof item.combinations === 'string') {
            return [item.combinations]
          }
          return []
        })
      }

      const flattenedCombinations = flattenCombinations(
        this.comparisonGroup.combination,
      )
      this.sectionSummaries['comparison-group'] =
        flattenedCombinations.length > 0
          ? `${flattenedCombinations.join(', ')}`
          : 'No comparison group selected'
    },

    async updateOutcomes(results) {
      this.outcome = results
      const response = await axios.post('jca/saveOutcomes', this.outcome)
      const newIds = response.data // Assuming this is the array of new IDs

      const idsToUse =
        newIds.length > 0 ? newIds : results.map((outcome) => outcome.id)

      this.outcome = this.outcome.map((outcome, index) => ({
        ...outcome,
        id: idsToUse[index],
      }))

      const saveOutcomeResult = this.outcome.filter((out) => out.selected)
      this.currentOutcomeIds = saveOutcomeResult.map((out) => out.id)
      this.sectionSummaries['specify-outcome'] = saveOutcomeResult
        .map((out) => out.name)
        .join(', ')
    },

    async savePICO() {
      try {
        // Fetch the maximum visualization ID
        const maxVisualizationIdRequestResult = await axios.get(
          'jca/getMaxVisualizationId',
        )
        this.maxVisualizationId = maxVisualizationIdRequestResult.data + 1

        // Log current comparison group ID

        // Prepare the PICO basic data
        const picoBasicData = {
          id: this.id,
          visualizationId: this.maxVisualizationId,
          jcaId: parseInt(this.localJcaId),
          country: this.selectedCountry,
          populationId: this.currentPopulationId,
          interventionId: this.currentInterventionId,
          comparisonGroupId: this.currentComparisonGroupId, // Single comparison group ID
          outcomeIds: this.currentOutcomeIds,
        }

        console.log('Saving PICO:')
        // Send the request to save the PICO
        const response = await axios.post('jca/savePico', picoBasicData)

        // Provide feedback upon success
        this.saveFeedback = {
          message: 'PICO saved successfully!',
          type: 'success',
        }

        // Update the ID with the response from the server
        this.id = response.data // Assuming the server returns the saved ID
      } catch (error) {
        console.error('Error saving PICO:', error)
        this.saveFeedback = {
          message: 'Error saving PICO. Please try again.',
          type: 'error',
        }
      }
    },
    async saveNewPICO() {
      try {
        // Fetch the maximum visualization ID
        const maxVisualizationIdRequestResult = await axios.get(
          'jca/getMaxVisualizationId',
        )
        this.maxVisualizationId = maxVisualizationIdRequestResult.data + 1

        // Prepare the PICO basic data
        const picoBasicData = {
          id: -1,
          visualizationId: this.maxVisualizationId,
          jcaId: parseInt(this.localJcaId),
          country: this.selectedCountry,
          populationId: this.currentPopulationId,
          interventionId: this.currentInterventionId,
          comparisonGroupId: this.currentComparisonGroupId, // Single comparison group ID
          outcomeIds: this.currentOutcomeIds,
        }

        // Send the request to save the PICO
        const response = await axios.post('jca/savePico', picoBasicData)

        // Provide feedback upon success
        this.saveFeedback = {
          message: 'PICO saved successfully!',
          type: 'success',
        }

        // Update the ID with the response from the server
        this.id = response.data // Assuming the server returns the saved ID
      } catch (error) {
        console.error('Error saving PICO:', error)
        this.saveFeedback = {
          message: 'Error saving PICO. Please try again.',
          type: 'error',
        }
      }
    },
    cancelPICO() {
      console.log('Canceling PICO definition')
    },

    async handlePicoSelection(selectedPico) {
      this.id = selectedPico.id
      this.selectedPico = selectedPico
      if (selectedPico && selectedPico.country) {
        this.selectedCountry = selectedPico.country

        const visualizationId = selectedPico.visualizationId // Use selectedPico.visualizationId
        const getPopulationDataForVisualizationIdResult = await axios.get(
          `jca/getPopulationForVisualizationId/${visualizationId}`,
        )
        const interventionDataFromBackendForVisualizationId = await axios.get(
          `jca/getInterventionDataForVisualizationId/${visualizationId}`,
        )

        // Fetch comparison group IDs
        const response = await axios.get(
          `jca/getComparisionGroupForVisualizationId/${visualizationId}`,
        )
        const comparisonGroupData = response.data

        // Update currentComparisonGroupId with the fetched data
        this.currentComparisonGroupId = comparisonGroupData.map(
          (group) => group.id,
        ) // Assuming each group has an 'id' field

        const outcomeResponse = await axios.get(
          `jca/getOutcomeDataForVisualizationId/${visualizationId}`,
        )
        const outcomesForVisualizationId = outcomeResponse.data.map((item) => ({
          id: item.id,
        }))

        const interventionId =
          interventionDataFromBackendForVisualizationId.data[0].id
        const populationDataForVisualizationId =
          getPopulationDataForVisualizationIdResult.data[0].id

        this.localJcaId = parseInt(this.localJcaId)
        this.country = this.selectedCountry
        this.currentPopulationId = populationDataForVisualizationId // Ensure this is the correct assignment
        this.population = getPopulationDataForVisualizationIdResult.data[0]
        this.intervention =
          interventionDataFromBackendForVisualizationId.data[0]
        this.comparisonGroup = comparisonGroupData[0]
        this.currentInterventionId = interventionId
        this.currentOutcomeIds = outcomesForVisualizationId.map(
          (item) => item.id,
        )
      } else {
        this.selectedCountry = ''
        this.sectionSummaries['select-country'] = ''
        this.isExpanded['select-country'] = true
      }
    },
    handleCountrySelection() {
      if (this.selectedCountry) {
        this.sectionSummaries['select-country'] = `${this.selectedCountry}`
        this.activeTab = 'population'
      }
    },
    updateSelectedOutcomes(selectedOutcomes) {
      console.log('Selected outcomes:', selectedOutcomes)
      this.currentOutcomeIds = selectedOutcomes.map((outcome) => outcome.id)
      this.outcome = selectedOutcomes
      console.log('Current comparison group:', this.currentOutcomeIds)
    },
    handlePopulationSelection(population) {
      this.currentPopulationId = population.id
      this.population = population
    },
    handleComparatorSelection(comparator) {
      this.currentComparisonGroupId = comparator.value.id
      this.comparisonGroup = comparator.value
    },
  },
  watch: {
    jcaId: {
      immediate: true,
      handler(newVal) {
        this.localJcaId = newVal
      },
    },
    activeTab(newTab) {
      console.log('Switched to tab:', newTab)
    },
  },
  async mounted() {
    try {
      const response = await axios.get('jca/euCountries')
      this.euCountries = response.data
      // const picoResponse = await axios.get('jca/fetchPicos/' + this.assetId)
      // this.picos = picoResponse.data
      const backendData = await axios.get(`jca/getJCAId/${this.assetId}`)
      this.localJcaId = backendData.data
    } catch (error) {
      console.error('Error fetching EU countries:', error)
    }
  },
}
</script>

<style scoped>
.header-container {
  display: flex;
  align-items: center; /* Vertically center align */
  margin-bottom: 20px; /* Space between header and tabs */
}

.define-pico-header {
  margin-right: 10px; /* Space between header and selector */
}

.pico-selector {
  margin-left: auto; /* Push selector to the right */
}

.section-summary {
  font-size: 0.9em;
  color: #666;
  margin-top: 5px;
  padding-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* adjust as needed */
}

.feedback-message {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  font-weight: bold;
}

.feedback-message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.feedback-message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}
.missing-fields {
  margin-top: 20px;
  padding: 10px;
  background-color: #fef6d9;
  border: 1px solid #ffeeba;
  color: #856404;
  border-radius: 4px;
  font-weight: bold;
}
</style>
