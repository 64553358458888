<template>
  <div class="pico-consolidation">
    <PICOOverview :assetId="assetId" />
    <PICOConsolidation :assetId="assetId" />
  </div>
</template>

<script>
import PICOConsolidation from './PICOConsolidation.vue'
import PICOOverview from './PICOOverview.vue'

export default {
  name: 'PICOConsolidationMaster',
  components: {
    PICOOverview,
    PICOConsolidation,
  },
  props: {
    assetId: {
      type: String,
      required: true,
    },
  },
}
</script>
