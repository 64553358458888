<template>
  <div class="form-group">
    <label>Population Data:</label>

    <q-table
      :rows="populationData"
      :columns="columns"
      row-key="id"
      class="q-mt-md"
    >
      <template v-slot:body-cell-selected="props">
        <q-td>
          <q-radio v-model="selectedPopulation" :val="props.row" />
        </q-td>
      </template>

      <template v-slot:body-cell-description="props">
        <q-td>{{ props.row.description }}</q-td>
      </template>

      <template v-slot:body-cell-disease="props">
        <q-td>{{ props.row.disease }}</q-td>
      </template>

      <template v-slot:body-cell-diseaseCharacteristics="props">
        <q-td>{{ props.row.diseaseCharacteristics }}</q-td>
      </template>

      <template v-slot:body-cell-sociodemographicRestrictions="props">
        <q-td>{{ props.row.sociodemographicRestrictions }}</q-td>
      </template>

      <template v-slot:body-cell-previousTreatments="props">
        <q-td>{{ props.row.previousTreatments }}</q-td>
      </template>

      <template v-slot:body-cell-otherCriteria="props">
        <q-td>{{ props.row.otherCriteria }}</q-td>
      </template>
    </q-table>

    <q-btn
      @click="showPopulationModal = true"
      label="Add New Population"
      class="q-mt-md"
    />

    <q-dialog v-model="showPopulationModal" @hide="clearPopulationModal">
      <PopulationModal
        :selectedOutcomeOptions="selectedOutcomeOptions"
        @close="clearPopulationModal()"
        @addPopulation="addPopulation"
      />
    </q-dialog>

    <div class="form-actions">
      <q-btn label="Back" @click="emitBack" class="q-mt-md" />
      <q-btn label="Next" @click="emitNext" class="q-mt-md" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import PopulationModal from './PopulationModal.vue'

export default {
  name: 'PopulationForm',
  components: {
    PopulationModal,
  },
  props: {
    assetId: {
      type: String,
      required: true,
    },
    picoId: {
      type: Number,
      required: true,
    },
    visualizationId: {
      type: Number,
      required: true,
    },
    population: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showPopulationModal: false,
      populationData: [],
      selectedPopulation: null,
      columns: [
        {
          name: 'selected',
          label: 'Select',
          align: 'left',
          sortable: false,
        },
        {
          name: 'description',
          label: 'Description',
          align: 'left',
          field: 'description',
          sortable: true,
        },
        {
          name: 'disease',
          label: 'Disease',
          align: 'left',
          field: 'disease',
          sortable: true,
        },
        {
          name: 'diseaseCharacteristics',
          label: 'Disease Characteristics',
          align: 'left',
          field: 'diseaseCharacteristics',
          sortable: true,
        },
        {
          name: 'sociodemographicRestrictions',
          label: 'Sociodemographic Restrictions',
          align: 'left',
          field: 'sociodemographicRestrictions',
          sortable: true,
        },
        {
          name: 'previousTreatments',
          label: 'Previous Treatments',
          align: 'left',
          field: 'previousTreatments',
          sortable: true,
        },
        {
          name: 'otherCriteria',
          label: 'Other Criteria',
          align: 'left',
          field: 'otherCriteria',
          sortable: true,
        },
      ],
      populationDataForVisualizationId: [],
    }
  },
  methods: {
    emitBack() {
      this.$emit('navigate', 'outcome')
    },
    emitNext() {
      this.$emit('navigate', 'intervention')
    },
    emitData() {
      if (this.selectedPopulation) {
        this.$emit('update:population', {
          picoId: this.selectedPopulation.picoId,
          assetId: this.selectedPopulation.assetId,
          description: this.selectedPopulation.description,
          disease: this.selectedPopulation.disease,
          diseaseCharacteristics:
            this.selectedPopulation.diseaseCharacteristics,
          sociodemographicRestrictions:
            this.selectedPopulation.sociodemographicRestrictions,
          previousTreatments: this.selectedPopulation.previousTreatments,
          otherCriteria: this.selectedPopulation.otherCriteria,
          id: this.selectedPopulation.id,
        })
      }
    },
    addPopulation(newPopulation) {
      const existingPopulation = this.populationData.find(
        (pop) =>
          pop.description === newPopulation.description &&
          pop.disease === newPopulation.disease,
      )

      if (!existingPopulation) {
        const populationWithIds = {
          ...newPopulation,
          picoId: this.picoId,
          assetId: this.assetId,
          id: -1, // Use a temporary ID
        }
        this.populationData.push(populationWithIds)
        this.selectedPopulation = populationWithIds // Optionally select the newly added population
        this.showPopulationModal = false
        this.emitData() // Emit data after adding a new population
      } else {
        console.log('Population already exists:', existingPopulation)
      }
    },
    clearPopulationModal() {
      this.showPopulationModal = false
    },
    setSelectedPopulation() {
      // Find the matching population in populationData
      const matchingPopulation = this.populationData.find(
        (pop) => pop.id === this.population.id,
      )

      if (matchingPopulation) {
        this.selectedPopulation = matchingPopulation // Set selectedPopulation to the found matching object
      } else {
        this.selectedPopulation = null // Reset if no match is found
      }
    },
    async fetchPopulationData() {
      try {
        const response = await axios.get(
          `jca/getPopulationDatas/${this.assetId}`,
        )

        this.populationData = response.data.map((item) => ({
          picoId: this.picoId,
          assetId: this.assetId,
          description: item.description || '',
          disease: item.disease || '',
          diseaseCharacteristics: item.diseaseCharacteristics || '',
          sociodemographicRestrictions: item.sociodemographicRestrictions || '',
          previousTreatments: item.previousTreatments || '',
          otherCriteria: item.otherCriteria || '',
          id: item.id,
        }))

        // Set selectedPopulation based on the population prop
        this.setSelectedPopulation()
      } catch (error) {
        console.error('Error fetching population data:', error)
      }
    },
  },
  watch: {
    selectedPopulation(newValue) {
      if (newValue) {
        this.$emit('select-population', newValue) // Emit the event with the new value
      }
    },
    population(newValue) {
      console.log('population prop changed:', newValue)
      this.setSelectedPopulation() // Call the method to set selectedPopulation
    },
  },
  async mounted() {
    await this.fetchPopulationData()
    this.selectedPopulation = this.population
  },
}
</script>
