<!-- PICOEvidenceLibrary.vue -->
<template>
  <div>
    <h5 class="q-my-none">PICO-specific Evidence library</h5>
    <PICOSelector :picos="picos" @pico-selected="handlePicoSelection" />
    <PICOConsolidation :assetId="assetId" />
    <div v-if="selectedPICO">
      <div>
        <p>Population: {{ selectedPICO.population }}</p>
        <p>Intervention: {{ selectedPICO.intervention }}</p>
        <p>Comparator: {{ selectedPICO.comparator }}</p>
        <!--<p>Outcome(s): {{ truncateText(selectedPICO.outcomes.join(', ')) }}</p>-->
      </div>
      <button @click="showAddEvidence = true">
        Add evidence to the evidence library
      </button>
      <button>Revise existing evidence in the evidence library</button>
    </div>
    <AddEvidence v-if="showAddEvidence" :selectedPICO="selectedPICO" />
  </div>
</template>

<script>
import AddEvidence from './AddEvidence.vue'
import PICOConsolidation from './PICOConsolidation.vue'
import PICOSelector from './PICOSelector.vue'

export default {
  name: 'PICOEvidenceLibrary',
  components: {
    AddEvidence,
    PICOConsolidation,
    PICOSelector,
  },
  props: {
    assetId: {
      type: String,
      required: true,
    },
    picos: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedPICO: null,
      showAddEvidence: false,
    }
  },
  methods: {
    truncateText(text, maxLength = 100) {
      if (text.length <= maxLength) {
        return text
      }
      return `${text.slice(0, maxLength)}...`
    },
    handlePicoSelection(selectedPico) {
      this.selectedPICO = selectedPico
      this.selectedCountry = this.selectedPico ? this.selectedPico.country : ''
    },
  },
}
</script>
