<template>
  <div class="module-content neumorphic-container">
    <div class="text-primary text-h4 mb-4 text-center">Asset Overview</div>
    <q-tabs v-model="activeSection" dense>
      <q-tab name="basic-data" label="Basic Data" />
      <q-tab name="pico-per-country" label="PICO per Country" />
      <q-tab name="pico-consolidation" label="PICO Consolidation" />
      <q-tab name="evidence-library" label="Evidence Library" />
    </q-tabs>

    <q-tab-panels v-model="activeSection">
      <q-tab-panel name="basic-data">
        <BasicDataForm
          v-if="selectedOption"
          :initialData="selectedOption"
          @confirm="handleConfirm"
        />
      </q-tab-panel>
      <q-tab-panel name="pico-per-country">
        <PICOForm
          v-if="selectedOption"
          :jcaId="localJcaId"
          :assetId="assetId"
          :assetName="assetName"
          :picos="picos"
          @confirm="handleConfirm"
        />
      </q-tab-panel>
      <q-tab-panel name="pico-consolidation">
        <PICOConsolidationMaster
          v-if="selectedOption"
          :assetId="assetId"
          @confirm="handleConfirm"
        />
      </q-tab-panel>
      <q-tab-panel name="evidence-library">
        <PICOEvidenceLibrary
          v-if="selectedOption"
          :assetId="assetId"
          :picos="picos"
          @confirm="handleConfirm"
        />
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script>
import axios from 'axios'
import BasicDataForm from './BasicDataForm.vue'
import PICOConsolidationMaster from './PICOConsolidationMaster.vue'
import PICOEvidenceLibrary from './PICOEvidenceLibrary.vue'
import PICOForm from './PICOForm.vue'

export default {
  name: 'AssetOverview',
  components: {
    BasicDataForm,
    PICOForm,
    PICOConsolidationMaster,
    PICOEvidenceLibrary,
  },
  props: {
    selectedOption: Object,
    assetId: Number,
    picos: Object,
    jcaId: Number,
    assetName: String,
  },
  data() {
    return {
      localJcaId: this.jcaId || 0,
      activeSection: 'basic-data', // Default to Basic Data tab
    }
  },
  methods: {
    async handleConfirm({ data, source }) {
      if (source === 'basic-data') {
        const response = await axios.post('jca/saveBasicDatas', data)
        this.localJcaId = response.data // Update local state

        // Switch to the "PICO per Country" tab
        this.activeSection = 'pico-per-country'

        // Emit event to notify parent if necessary
        this.$emit('update:jcaId', this.localJcaId)
      }
    },
  },
}
</script>

<style lang="scss">
@import './jcaStyle.scss';
</style>
