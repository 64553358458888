<template>
  <div class="modal-overlay">
    <div class="modal-content">
      <div class="modal-header">
        <h3>Add New Outcome</h3>
        <button class="close-button" @click="$emit('close')">&times;</button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Outcomes:</label>
          <div class="outcome-group">
            <div class="form-control">
              <input
                type="text"
                v-model="newOutcome.name"
                placeholder="Enter outcome"
                class="input-field"
              />
            </div>
            <div class="form-control">
              <label>Outcome Category:</label>
              <select v-model="newOutcome.category" class="select-field">
                <option value="">Select category</option>
                <option value="mortality">Mortality</option>
                <option value="morbidity">Morbidity</option>
                <option value="hrqol">HrQoL</option>
                <option value="safety">Safety</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div class="form-control">
              <label>Outcome Measure:</label>
              <input
                type="text"
                v-model="newOutcome.outcomeMeasure"
                placeholder="Enter OutcomeMeasure"
                class="input-field"
              />
            </div>
            <div class="form-control">
              <label>Time of Assessment:</label>
              <input
                type="text"
                v-model="newOutcome.timeAssessment"
                placeholder="Enter time of assessment"
                class="input-field"
              />
            </div>
            <div class="form-control">
              <label>Statistical Measurement for Treatment Effect Size:</label>
              <input
                type="text"
                v-model="newOutcome.statisticalMeasurement"
                placeholder="Enter statistical measurement"
                class="input-field"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn-confirm" @click="confirmOutcome">Confirm</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OutcomeModal',
  props: {
    selectedOutcomeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedOutcome: null,
      newOutcome: {
        id: -1,
        picoId: null, // You'll need to pass this from the parent component
        name: '',
        category: '',
        outcomeMeasure: '',
        timeAssessment: '',
        statisticalMeasurement: '',
      },
    }
  },
  methods: {
    handleModelValueUpdate(value) {
      console.log('Selected outcome form:', value.value)

      if (value.value === null) {
        this.newOutcome = {
          id: -1,
          picoId: null, // Set the picoId here
          name: '',
          category: '',
          outcomeMeasure: '',
          timeAssessment: '',
          statisticalMeasurement: '',
        }
      } else {
        const selectedOutcome = value.value
        this.newOutcome = {
          id: selectedOutcome.id || -1,
          picoId: null, // Set the picoId here
          name: selectedOutcome.name || '',
          category:
            this.mapCategoryToDropdownValue(selectedOutcome.category) || '',
          outcomeMeasure: selectedOutcome.outcomeMeasure || '',
          timeAssessment: selectedOutcome.timeAssessment || '',
          statisticalMeasurement: selectedOutcome.statisticalMeasurement || '',
        }
      }
    },
    mapCategoryToDropdownValue(category) {
      const categoryMapping = {
        mortality: 'mortality',
        morbidity: 'morbidity',
        hrqol: 'hrqol',
        safety: 'safety',
        other: 'other',
      }

      return categoryMapping[category.toLowerCase()] || ''
    },
    confirmOutcome() {
      // Check if all required fields are filled
      if (
        !this.newOutcome.name.trim() ||
        !this.newOutcome.category ||
        !this.newOutcome.outcomeMeasure.trim() ||
        !this.newOutcome.timeAssessment.trim() ||
        !this.newOutcome.statisticalMeasurement.trim()
      ) {
        alert('Please fill in all required fields.')
        return
      }

      // Emit the addOutcome event with the new outcome data
      this.$emit('addOutcome', this.newOutcome)
      this.$emit('close')
    },
  },
}
</script>
